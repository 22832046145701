import React from 'react';
import Card from '@atoms/Card';

import { getColor, getTextColor } from '@services/utils.service';

const TransientThoughtResult = ({
  data = [],
  settings = {},
  colors = {},
  fontFamily
}) => {
  return (
    <div className="overflow-y-auto flex flex-col p-3 gap-y-2 z-10 h-full">
      <div className="flex flex-col p-3 gap-y-2">
        {data.length > 0 ? data.map((comment, index) => <Card key={index} style={{ backgroundColor: getColor(colors, index, colors.ttBgColor) }} className='flex justify-between px-4 py-4 lg:py-5 rounded-lg' >
          <p style={{ color: getTextColor(colors, index), fontFamily }} className={'text-lg font-semibold md:leading-6 lg:leading-7 overflow-hidden break-words'}>{comment.body || comment.words}</p>
          {settings?.transientThoughtShowUsername && <p style={{ color: getTextColor(colors, index) }} className={'text-sm  font-normal  md:leading-6 lg:leading-7 overflow-hidden break-words'}>{comment.username}</p>}
        </Card>)
          : <p className={'text-base text-center font-OpenSans font-semibold text-neutral-60 lg:text-xl italic  md:leading-6 lg:leading-7 overflow-hidden break-words'}>No comments to display</p>
        }

      </div>
    </div>
  );
};

export default TransientThoughtResult;
